import React from "react"
import Layout from "components/Lucilades/Layout"
import SEO from "components/SEO"

import { useStaticQuery, Link, graphql } from "gatsby"
export default function LuciladePage({ data }) {
  const lucilade = data.sanityEntry
  return (
    <Layout title={lucilade.content} author={lucilade.author}>
      <SEO
        title={lucilade.title}
        description={lucilade.content}
        pathname={lucilade.slug.content}
      />
    </Layout >
  )
}

export const query = graphql`
  query($slug: String!) {
    sanityEntry(slug: {current: {eq: $slug}}) {
      slug {
        current
      },
      title
      author
      publishedAt(formatString: "Do MMMM YYYY", locale: "fr")
      content
    }
  }
`